import * as React from "react";
import { Br, Text, Container, PageHeader, Layout, Button } from "components";
import ctl from "@netlify/classnames-template-literals";

const GraduateIntershipPage = () => {
  return (
    <Layout title="Careers - Graduate Internship Program">
      <PageHeader
        title={<>GRADUATE INTERNSHIP PROGRAM</>}
        subheading={
          <>
            <ul className={role}>
              {roles.map((role, index) => {
                return (
                  <li className={list} key={index}>
                    {role}
                  </li>
                );
              })}
            </ul>
          </>
        }
      />

      <Container>
        <div className="mt-[30px]">
          <Text value={<>Requirements</>} variant="h4" />
          <Text value={<>Graduate Interns must:</>} variant="p18" />
          <ul>
            {reqs.map(req => {
              return (
                <li className="mb-[10px] font-light" key={req.id}>
                  - {req.reqm}
                </li>
              );
            })}
            <li></li>
          </ul>
        </div>

        <div className="mt-[30px]">
          <Text value={<>Duration</>} variant="h4" />
          <Text
            className="md:leading-[32px]"
            value={
              <>
                Selection is based on performance during the assessments and
                subject to manpower need. <Br on="desktop" />
                The graduate trainee period for each candidate is for one year.{" "}
              </>
            }
            variant="p"
          />
        </div>

        <div className="mt-[30px]">
          <Text value={<>Method of Application</>} variant="h4" />
          <Text
            className="md:leading-[32px]"
            value={
              <>
                An up-to-date Curriculum Vitae to be sent to{" "}
                <a className="text-primary" href="mailto:careers@parkway.ng">
                  careers@parkway.ng
                </a>{" "}
                with 2022 internship recruitment clearly stated as the subject
                of your mail.
              </>
            }
            variant="p"
          />
          <Text
            className="md:leading-[32px]"
            value={
              <>
                Please note that only applications with required qualification
                will be considered.
              </>
            }
            variant="p"
          />
          <Text
            className="md:leading-[32px]"
            value={
              <>No fees or payments are required for application or selection</>
            }
            variant="p"
          />
          <Text
            className="md:leading-[32px]"
            value={
              <>
                You can also attach your CV (pdf format only) by clicking on the
                submit icon below.
              </>
            }
            variant="p"
          />
          <div className="mt-[20px]">
            <Button
              text="Submit CV"
              size="small"
              to="mailto:careers@parkway.ng"
            />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

const role = ctl(`
flex
gap-[20px]
max-w-[1000px]
text-left
flex-wrap
`);

const list = ctl(`
rounded-full
bg-primary
lg:px-[10px]
px-[5px]
md:text-[16px]
text-[12px]
`);

const roles = [
  "Software Developers",
  "Software Testers",
  "Product Managers",
  "Implementation Engineers",
  "Database Administrators",
];
const reqs = [
  {
    id: 1,
    reqm: "Have a first degree in Computer Science, Electronics or Computer Engineering, and other applied science or related discipline.",
  },
  {
    id: 2,
    reqm: "Have completed NYSC.",
  },
  {
    id: 3,
    reqm: "Have strong communication and interpersonal skills; and an ability to work in a team.",
  },
  {
    id: 4,
    reqm: "All applicants would be subjected to interview/assessments.",
  },
];

export default GraduateIntershipPage;
